import Vue from 'vue'
import App from './App.vue'
import './styles/reset.css'
import router from '@/router/index.js'
import { Popover } from 'element-ui';

Vue.use(Popover);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
