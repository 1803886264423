import Vue from 'vue'
import VueRouter from 'vue-router';
import { handleGetOs } from '@/utils/tools.js'

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import('@/components/Home.vue')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('@/components/Mobile.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/Download.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const { isMobile } = handleGetOs()
  if (to.name !== 'Mobile' && isMobile) {
    next({name: 'Mobile'})
  }
  next()
})

export default router