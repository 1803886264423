export const handleGetOs = () => {
  const ua = navigator.userAgent;
  // 操作系统目前支持浏览器的 暂列为两个 Windows mac
  const isMacOs =  /(?:Macintosh)/.test(ua);
  const isMacPc = isMacOs && navigator.maxTouchPoints < 2;
  const isWindowsPc = /(?:Windows)/.test(ua);
  // phone-pad 操作系统 ios android
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  const isIPad = /(?:iPad|PlayBook)/.test(ua) || (isMacOs && navigator.maxTouchPoints > 1);
  const isAPad = (isAndroid && !/(?:Mobile)/.test(ua))||(isAndroid && /(?:PAD)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
  const isTablet = isIPad || isAPad;
  const isiPhone = /(?:iPhone)/.test(ua) && !isTablet;
  const isPhone = isiPhone || (!isTablet && isAndroid);
  const isPc = !isPhone && !isAndroid && !isTablet;
  const isWeChat = (/MicroMessenger/i).test(ua);
  const isMobile = /Android|webOS|iPhone|iPad/i.test(navigator.userAgent);

  return {
    isMacPc,
    isWindowsPc,
    isWeChat,
    isTablet,
    isIPad,
    isAPad,
    isPhone,
    isiPhone,
    isAndroid,
    isPc,
    isMobile
  };
};